import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import {
  ROUTES,
  ROUTES_LOGGED_IN,
  ROUTES_LOGGED_OUT,
  MENU_ITEM_ROUTES,
  ROUTES_ADMIN_LOGGED_IN
} from '../../const';
import { hasRole, USERS_ADMIN } from '../../utils/roles';
import { toastError } from '../common/Toast';

const Dashboard = ({ location, history, user }) => {
  const handleItemClick = (e, { name }) => {
    const route = ROUTES.find(route => route.name === name);
    if (route) {
      history.push(route.pathName);
    } else {
      toastError('Wrong way');
    }
  };
  const { pathname } = location;
  const routesLoggedIn = user
    ? hasRole(user, USERS_ADMIN)
      ? ROUTES_ADMIN_LOGGED_IN
      : ROUTES_LOGGED_IN
    : [];

  return (
    <Menu inverted secondary className="dashboard">
      {!user
        ? ROUTES_LOGGED_OUT.map(({ name, pathName }) => (
            <Menu.Item
              key={name}
              name={name}
              active={pathname === pathName}
              onClick={handleItemClick}
              className="dashboard-button"
            />
          ))
        : routesLoggedIn
            .filter(item => MENU_ITEM_ROUTES.includes(item.name))
            .map(({ name, pathName }) => (
              <Menu.Item
                key={name}
                name={name}
                active={pathname === pathName}
                onClick={handleItemClick}
                className="dashboard-button"
              />
            ))}
    </Menu>
  );
};

export default withRouter(Dashboard);
