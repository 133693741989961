import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './components/App';
import { clientId, commitHash, hostedDomain, uri } from './config';

const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
  const userToken = localStorage.getItem('userToken');

  return {
    headers: {
      ...headers,
      authorization: userToken ? `Bearer ${userToken}` : ''
    }
  };
});

const httpLink = new HttpLink({
  uri
});
const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink)
});

const initGoogleClient = () => {
  const { gapi: { client: { init } = {} } = {} } = window || {};
  if (init) {
    init({
      clientId,
      scope: 'profile',
      hosted_domain: hostedDomain
    });
  } else {
    console.error('init google client error');
  }
};

const loadAuth2 = () => {
  const { gapi } = window;
  if (gapi) {
    gapi.load('client:auth2', initGoogleClient);
  } else {
    console.error('init google client error');
  }
};

window.addEventListener('load', () => {
  const root = document.getElementById('root');
  loadAuth2();
  ReactDOM.render(
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>,
    root
  );
});

window.VERSION = commitHash;
