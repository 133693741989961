import React from 'react';
import { Icon } from 'semantic-ui-react';

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found-text">
        404 Page not found
        <Icon name="frown outline" size="large" />
      </div>
    </div>
  );
};

export default NotFound;
