import React, { Fragment } from 'react';
import { Button, Table, Modal, Rating, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import moment from 'moment';

import { USERS_ADMIN, hasRole } from '../../../utils/roles';
import { GET_USERS } from '../../CompanyUsersTable';
import { GET_SKILL_ENTRIES } from '../../UserSettings/HistoryTable';
import { toastSucces, toastError } from '../../common/Toast';
import { ARCHIVE_USER } from '../../UserTable/MenuUsers';

const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(_id: $id)
  }
`;

const DELETE_SKILL_ENTRY = gql`
  mutation deleteSkillEntry($id: ID!) {
    deleteSkillEntry(_id: $id)
  }
`;

const Row = ({
  archive,
  userArchived,
  creationDatetime = null,
  currentUser = {},
  endPoint = '/',
  hasRated = true,
  knowledge,
  label,
  liking,
  skillEntryId
}) => {
  const [, , id] = endPoint.split('/');
  const isToday = moment(creationDatetime, 'MM-DD-YYYY').isSame(
    new Date(),
    'day'
  );
  const [openRestoreModal, setOpenRestoreModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  return (
    <Mutation
      mutation={ARCHIVE_USER}
      refetchQueries={() => [{ query: GET_USERS }]}
    >
      {archiveUser => {
        return (
          <Mutation
            mutation={DELETE_USER}
            refetchQueries={() => [{ query: GET_USERS }]}
          >
            {deleteUser => {
              return (
                <Table.Row
                  className={userArchived ? 'archived-user-row' : null}
                >
                  <Table.Cell>
                    {endPoint !== '/' ? (
                      <Link className="table-links" to={endPoint}>
                        {label}
                      </Link>
                    ) : (
                      <span>{label}</span>
                    )}

                    {hasRole(currentUser, USERS_ADMIN) && (
                      <div className="table-buttons">
                        {archive && (
                          <Modal
                            open={openRestoreModal}
                            onClose={() => setOpenRestoreModal(false)}
                            onOpen={() => setOpenRestoreModal(true)}
                            trigger={
                              <Button icon labelPosition="left">
                                Restore
                                <Icon name="redo" />
                              </Button>
                            }
                          >
                            <Modal.Content>
                              <p>
                                Are you sure you want to restore this user
                                profile?
                              </p>
                            </Modal.Content>
                            <Modal.Actions>
                              <Button
                                color="red"
                                onClick={() => setOpenRestoreModal(false)}
                              >
                                <Icon name="remove" /> No
                              </Button>
                              <Button
                                color="green"
                                onClick={async () => {
                                  setOpenRestoreModal(false);
                                  const { data } = await archiveUser({
                                    variables: { id, archive: false }
                                  });
                                  if (data) {
                                    toastSucces('User has been restored');
                                  } else {
                                    toastError('User has not been restored');
                                  }
                                }}
                              >
                                <Icon name="checkmark" /> Yes
                              </Button>
                            </Modal.Actions>
                          </Modal>
                        )}

                        <Modal
                          open={openDeleteModal}
                          onClose={() => setOpenDeleteModal(false)}
                          onOpen={() => setOpenDeleteModal(true)}
                          trigger={
                            <Button negative icon labelPosition="left">
                              Delete
                              <Icon name="trash" />
                            </Button>
                          }
                        >
                          <Modal.Content>
                            <p>
                              Are you sure you want to delete this profile? If
                              yes, please take caution that this data won’t be
                              available anymore.
                            </p>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button
                              color="red"
                              onClick={() => setOpenDeleteModal(false)}
                            >
                              <Icon name="remove" /> No
                            </Button>
                            <Button
                              color="green"
                              onClick={async () => {
                                setOpenDeleteModal(false);
                                const {
                                  data: { deleteUser: isDeletedUser } = {}
                                } = await deleteUser({
                                  variables: { id }
                                });

                                if (isDeletedUser) {
                                  toastSucces('User has been deleted');
                                } else {
                                  toastError('User has not been deleted');
                                }
                              }}
                            >
                              <Icon name="checkmark" /> Yes
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </div>
                    )}
                  </Table.Cell>
                  {hasRated && (
                    <Fragment>
                      <Table.Cell>
                        <Rating
                          icon="star"
                          rating={knowledge}
                          maxRating={5}
                          disabled
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Rating
                          icon="heart"
                          rating={liking}
                          maxRating={5}
                          disabled
                        />
                      </Table.Cell>
                    </Fragment>
                  )}
                  {creationDatetime && (
                    <Table.Cell>
                      {creationDatetime}
                      <Mutation
                        mutation={DELETE_SKILL_ENTRY}
                        refetchQueries={() => [{ query: GET_SKILL_ENTRIES }]}
                      >
                        {deleteSkillEntry => {
                          return (
                            <Fragment>
                              {isToday && (
                                <Icon
                                  name="delete"
                                  className="table-delete"
                                  onClick={async () => {
                                    const {
                                      data: {
                                        deleteSkillEntry: isDeleteSkillEntry
                                      } = {}
                                    } = await deleteSkillEntry({
                                      variables: { id: skillEntryId }
                                    });

                                    if (isDeleteSkillEntry) {
                                      toastSucces(
                                        'SkillEntry has been deleted'
                                      );
                                    } else {
                                      toastError(
                                        'SkillEntry has not been deleted'
                                      );
                                    }
                                  }}
                                />
                              )}
                            </Fragment>
                          );
                        }}
                      </Mutation>
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            }}
          </Mutation>
        );
      }}
    </Mutation>
  );
};

export default Row;
