import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Button } from 'semantic-ui-react';

import { ROUTE_LOGIN } from '../../const';
import { toastError } from '../common/Toast';

const Logout = ({ history, refetch }) => {
  const handleSignOutClick = () => {
    const { gapi: { auth2 } = {} } = window || {};
    if (auth2) {
      auth2.getAuthInstance().disconnect();
      localStorage.removeItem('userToken');
      history.push(ROUTE_LOGIN);
      refetch();
    } else {
      toastError('Unexpected error');
    }
  };

  return (
    <Button inverted onClick={handleSignOutClick}>
      <Icon name="google" />
      Logout
    </Button>
  );
};

export default withRouter(Logout);
