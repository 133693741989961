import React, { Fragment } from 'react';
import { Table, Rating } from 'semantic-ui-react';

const Row = ({ knowledge, liking, label, skillId, userId, handleRating }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <span>{label}</span>
      </Table.Cell>

      <Fragment>
        <Table.Cell>
          <Rating
            icon="star"
            rating={knowledge || 0}
            maxRating={5}
            clearable
            onRate={(e, { rating }) => {
              handleRating({
                name: label,
                knowledge: rating,
                liking,
                skillId,
                userId
              });
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Rating
            icon="heart"
            rating={liking || 0}
            maxRating={5}
            clearable
            onRate={(e, { rating }) => {
              handleRating({
                name: label,
                knowledge,
                liking: rating,
                skillId,
                userId
              });
            }}
          />
        </Table.Cell>
      </Fragment>
    </Table.Row>
  );
};

export default Row;
