import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';

export const GET_ALL_SKILLS = gql`
  query {
    skills {
      name
      _id
    }
  }
`;

const MenuSkills = ({ skill, onSetSkill }) => {
  return (
    <Query query={GET_ALL_SKILLS}>
      {({ data = {}, loading, error }) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const { skills } = data || {};
        const options = skills
          .map((skill, i) => {
            return {
              key: i,
              text: skill.name,
              value: skill._id
            };
          })
          .sort((a, b) => a.text.localeCompare(b.text));

        return (
          <Dropdown
            placeholder="Choose skill"
            value={skill}
            options={options}
            selection
            onChange={(e, { value, options }) => {
              const { text = '' } = options.find(option => {
                return option.value === value;
              });
              onSetSkill({ selectedId: value, skillName: text });
            }}
          />
        );
      }}
    </Query>
  );
};
export default MenuSkills;
