import React, { useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { toast } from 'react-semantic-toasts';

import { ratingLabels, likingLabels } from '../SkillEntryForm/getLabel';
import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';
import { GET_SKILLS } from '../CompanySkillsTable';
import { GET_USER } from '../UserTable';
import { GET_SKILL } from '../SkillTable';
import Rating from '../Rating';

const CREATE_SKILL_ENTRY = gql`
  mutation createSkillEntryMutataion($input: CreateSkillEntryInput!) {
    createSkillEntry(input: $input)
  }
`;

export const GET_CURRENT_USER = gql`
  query user {
    currentUser {
      _id
    }
  }
`;

const AddSkill = ({
  skillId,
  name,
  initialKnowledge,
  initialLiking,
  hasSkill
}) => {
  const [knowledge, setKnowledge] = useState(initialKnowledge);
  const [liking, setLike] = useState(initialLiking);
  const [isOpen, setOpen] = useState(false);

  return (
    <Query query={GET_CURRENT_USER}>
      {({ data = {}, loading, error }) => {
        const {
          currentUser: { _id }
        } = data;

        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        return (
          <Mutation
            mutation={CREATE_SKILL_ENTRY}
            refetchQueries={() => [
              { query: GET_SKILLS },
              { query: GET_USER, variables: { userId: _id } },
              { query: GET_SKILL, variables: { skillId } }
            ]}
          >
            {(createSkillEntry, { loading, error }) => {
              const { message } = error || '';
              if (loading) return <Loader />;
              if (error) return <ErrorPage text={message} />;
              return (
                <div className="skill-table-add">
                  <h1>{name}</h1>
                  <Rating
                    range={[1, 5]}
                    descriptions={ratingLabels}
                    size="massive"
                    icon="star"
                    onRate={(e, { rating }) => setKnowledge(rating)}
                    rating={knowledge}
                  />
                  <Rating
                    range={[1, 5]}
                    descriptions={likingLabels}
                    size="massive"
                    icon="heart"
                    onRate={(e, { rating }) => setLike(rating)}
                    rating={liking}
                  />
                  <Button onClick={() => setOpen(true)} secondary>
                    {hasSkill ? 'Update Skill' : 'Add skill'}
                  </Button>

                  <Confirm
                    open={isOpen}
                    content={`Do you want add ${name} with knowledge ${knowledge} and liking ${liking}?`}
                    onCancel={() => setOpen(false)}
                    onConfirm={async () => {
                      if (!knowledge || !liking || !skillId) {
                        setOpen(false);
                        toast({
                          title: 'Empty field',
                          description:
                            'Please choose technology and set ratings',
                          animation: 'bounce'
                        });
                      } else {
                        setOpen(false);
                        createSkillEntry({
                          variables: {
                            input: {
                              knowledge,
                              liking,
                              skillId,
                              userId: _id
                            }
                          }
                        });
                      }
                    }}
                  />
                </div>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default AddSkill;
