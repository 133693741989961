import React from 'react';
import { Icon, Button } from 'semantic-ui-react';

import { toastError } from '../common/Toast';

const GoogleLogin = ({ login, refetch }) => {
  const handleSignInClick = () => {
    const { gapi: { auth2 } = {} } = window || {};

    if (auth2) {
      const authInstance = auth2.getAuthInstance();

      authInstance
        .signIn()
        .then(user => {
          const idToken = user.getAuthResponse().id_token;
          login({ variables: { webToken: idToken } }).then(refetch);
        })
        .catch(res => {
          const { error } = res || 'An error occurred';
          toastError(error);
        });
    } else {
      toastError('Unexpected error');
    }
  };

  return (
    <div className="login-container">
      <Button basic size="huge" color="blue" onClick={handleSignInClick}>
        <Icon disabled name="google" />
        Login
      </Button>
    </div>
  );
};

export default GoogleLogin;
