import React, { useState } from 'react';
import { Button, Input, Confirm } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';

import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';
import { GET_SKILLS } from '../CompanySkillsTable';
import { GET_ALL_SKILLS } from './MenuSkills';
import { toastError, toastSucces } from '../common/Toast';

const CREATE_SKILL = gql`
  mutation createSkillMutation($skillName: SkillName!) {
    createSkill(skillName: $skillName) {
      name
    }
  }
`;

const FIND_SKILL_DUPLICATES = gql`
  query getSkillDuplicates($skillName: String!) {
    skillDuplicates(skillName: $skillName) {
      name
      similarity
    }
  }
`;

const AddSkill = () => {
  // eslint-disable-next-line prefer-const
  const [skillName, setSkill] = useState(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <Query query={GET_ALL_SKILLS}>
      {({ data = {}, loading, error }) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;
        const { skills } = data;
        let skillDisplayedName = skillName;
        return (
          <Mutation
            mutation={CREATE_SKILL}
            refetchQueries={() => [
              { query: GET_SKILLS },
              { query: GET_ALL_SKILLS }
            ]}
          >
            {(createNewSkill, { loading, error }) => {
              const { message = '' } = error || {};

              if (loading) return <Loader />;
              if (error) return <ErrorPage text={message} />;
              if (skillDisplayedName) {
                skillDisplayedName = skillDisplayedName.trim();
              }
              return (
                <div>
                  <Input
                    onChange={e => setSkill(e.target.value)}
                    className="add-skill-db"
                    list="skills"
                  />
                  {skills && (
                    <datalist id="skills">
                      {skills.map((skill, index) => {
                        return (
                          <option
                            value={skill.name}
                            key={`${index}${skill.name}`}
                          />
                        );
                      })}
                    </datalist>
                  )}
                  <Button
                    onClick={() => setOpen(true)}
                    secondary
                    disabled={!skillDisplayedName}
                  >
                    Add technology to database
                  </Button>
                  {isOpen && (
                    <ConfirmWithDuplicateCheck
                      skillName={skillDisplayedName}
                      isOpen={isOpen}
                      setOpen={setOpen}
                      createNewSkill={createNewSkill}
                    />
                  )}
                </div>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

const ConfirmWithDuplicateCheck = ({
  skillName,
  isOpen,
  setOpen,
  createNewSkill
}) => {
  return (
    <Query
      query={FIND_SKILL_DUPLICATES}
      variables={{ skillName }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loader />;
        }

        if (error) {
          return <ErrorPage text={error.message} />;
        }

        const possibleDuplicates = data.skillDuplicates.map(({ name }) => name);

        return (
          <Confirm
            open={isOpen}
            content={
              <div className="content">
                <div>Do you want to add {skillName}?</div>
                {!!possibleDuplicates.length && (
                  <div style={{ color: 'red' }}>
                    There are already saved skills that may be duplicates:{' '}
                    {possibleDuplicates.join(', ')}.
                  </div>
                )}
              </div>
            }
            onCancel={() => setOpen(false)}
            onConfirm={async () => {
              if (!skillName) {
                setOpen(false);
                toastError('Empty field - database rejection');
              } else {
                const {
                  data: { createSkill: newSkill }
                } = await createNewSkill({ variables: { skillName } });
                setOpen(false);
                if (!newSkill) {
                  toastError('The entry is already in registry');
                } else {
                  toastSucces(`${newSkill.name} has beed added`);
                }
              }
            }}
          />
        );
      }}
    </Query>
  );
};

export default AddSkill;
