import React from 'react';

import Logout from '../Logout';

const Footer = ({ user, refetch }) => {
  const { firstName = '' } = user || {};
  return (
    <div className="footer">
      <div className="footer-contact">
        <div className="user-area">
          {user && <span className="user-container">Cześć {firstName}</span>}
        </div>
      </div>
      <div className="footer-logout">
        {user && <Logout refetch={refetch} />}
      </div>
    </div>
  );
};

export default Footer;
