import React from 'react';

import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

export const Toast = () => {
  return <SemanticToastContainer position="top-right" />;
};

export const toastSucces = description => {
  return toast({
    type: 'success',
    title: 'Success',
    description,
    animation: 'fade right'
  });
};

export const toastError = description => {
  return toast({
    type: 'error',
    title: 'Error',
    description,
    animation: 'fade right'
  });
};
