import React from 'react';
import { Link } from 'react-router-dom';

import Dashboard from '../Dashboard';

const Header = ({ user }) => {
  return (
    <div className="header-container">
      <Link to="/skills">
        <div className="logo" />
      </Link>
      <Dashboard user={user} />
    </div>
  );
};

export default Header;
