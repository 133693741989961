import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Loader from '../common/Loader';
import MenuUsers from './MenuUsers';
import Chart from './Chart';
import MenuSkills from './MenuSkills';
import TrendsChart from './TrendsChart';

const GET_USER = gql`
  query {
    currentUser {
      _id
      firstName
      lastName
      latestSkillEntries {
        skill {
          _id
          name
        }
      }
    }
  }
`;

const Charts = ({ initialUserId, initialSkillId }) => {
  const [userId, setUserId] = useState(initialUserId);
  const [skillId, setSkillId] = useState(initialSkillId);

  return (
    <div className="charts">
      <div className="charts-menu">
        <MenuUsers onSetUserId={setUserId} />
        <MenuSkills userId={userId} onSetSkillId={setSkillId} />
      </div>
      {skillId && <Chart userId={userId} skillId={skillId} />}
      <TrendsChart />
    </div>
  );
};

const Container = () => {
  return (
    <Query query={GET_USER}>
      {({ data = {}, loading, error }) => {
        if (loading) return <Loader />;
        if (error) return <div />;
        const {
          currentUser: { _id, latestSkillEntries }
        } = data;
        const initialSkillId =
          latestSkillEntries.length !== 0 && latestSkillEntries[0].skill._id;

        return <Charts initialSkillId={initialSkillId} initialUserId={_id} />;
      }}
    </Query>
  );
};

export default Container;
