import React, { Fragment } from 'react';
import moment from 'moment';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';

import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';
import {
  dataLikingOffset,
  domainLiking,
  domainKnowledge
} from './chartSettings';

export const GET_SKILL_ENTRY = gql`
  query($skillId: ID!, $userId: ID!) {
    user(_id: $userId) {
      firstName
      lastName
      skillEntries(skillId: $skillId) {
        creationDatetime
        knowledge
        liking
        skill {
          name
        }
      }
    }
  }
`;

const Chart = ({ userId, skillId }) => {
  return (
    <Query query={GET_SKILL_ENTRY} variables={{ userId, skillId }}>
      {({ data = {}, loading, error }) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const {
          user: { skillEntries, firstName, lastName }
        } = data;
        const strokes = skillEntries.map(
          ({ creationDatetime, knowledge, liking, skill }) => {
            return {
              time: moment(creationDatetime)
                .subtract(10, 'days')
                .calendar(),
              knowledge,
              liking: liking - dataLikingOffset,
              skillName: skill.name
            };
          }
        );

        const label = strokes[0] || {};
        return (
          <Fragment>
            <h1>{`${firstName} ${lastName}: ${
              label.skillName ? label.skillName : ''
            }`}</h1>
            <LineChart
              width={1000}
              height={500}
              data={strokes}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis
                yAxisId="liking"
                domain={domainLiking}
                label={{ value: 'Liking', position: 'insideTopLeft' }}
              />
              <YAxis
                yAxisId="knowledge"
                domain={domainKnowledge}
                label={{
                  value: 'Knowledge',
                  position: 'insideTopRight',
                  dx: 30
                }}
                orientation="right"
              />
              <Tooltip />
              <Legend />
              <Line
                yAxisId="liking"
                type="monotone"
                dataKey="liking"
                stroke="#FF0000"
                activeDot={{ r: 8 }}
              />
              <Line
                yAxisId="knowledge"
                type="monotone"
                dataKey="knowledge"
                stroke="#F9D71C"
              />
            </LineChart>
          </Fragment>
        );
      }}
    </Query>
  );
};

export default Chart;
