export const ratingLabels = [
  'Awareness',
  'Beginner',
  'Intermediate',
  'Advanced',
  'Expert'
];

export const likingLabels = [
  'I hate it',
  "I don't like it",
  'I neither like nor dislike',
  'I like it',
  'I love it'
];

export const ratingDescriptions = [
  "I'm aware of this technology and know where to find resources to learn more. With proper motivation, I'm ready to start exploring it.",
  'I understand the fundamentals but lack professional experience. I can contribute to projects with some guidance and expect to work independently soon.',
  'I have professional experience and can work on projects independently without regular assistance.',
  'I have extensive project experience and can confidently lead teams using this technology.',
  "I'm an expert who can lead complex projects and contribute to the technology's development. I have a deep understanding of its intricacies."
];

export const likingDescriptions = [
  "I strongly dislike working with this technology. It's frustrating and time-consuming, even for simple tasks.",
  "I can work with it if necessary, but I don't enjoy it. Significant incentives would be required for me to use it willingly.",
  "This technology is acceptable. It's neither particularly enjoyable nor problematic for me. I'm neutral about using it.",
  "I find this technology satisfying to use. It's user-friendly and gives me a sense of its potential for the future.",
  'This is one of my favorite technologies. I thoroughly enjoy working with it and see it as a key part of the future in this field.'
];
