import Login from './components/Login';
import CompanySkillsTable from './components/CompanySkillsTable';
import CompanyUsersTable from './components/CompanyUsersTable';
import SkillTable from './components/SkillTable';
import UserTable from './components/UserTable';
import SkillEntryForm from './components/SkillEntryForm';
import Charts from './components/Charts';
import UserSettings from './components/UserSettings';

export const ROUTE_LOGIN = '/';
export const ROUTE_SKILL = '/skill/:skillId';
export const ROUTE_USER = '/user/:userId';
export const ROUTE_ARCHIVE = '/archive';
export const ROUTE_SKILL_ENTRY = '/skill-entry';
export const ROUTE_USERS = '/users';
export const ROUTE_SKILLS = '/skills';
export const ROUTE_CHARTS = '/charts';
export const ROUTE_SETTINGS = '/settings';

export const ROUTES_LOGGED_IN = [
  { name: 'add skill', pathName: ROUTE_SKILL_ENTRY, Component: SkillEntryForm },
  {
    name: 'company skills',
    pathName: ROUTE_SKILLS,
    Component: CompanySkillsTable
  },
  { name: 'skill', pathName: ROUTE_SKILL, Component: SkillTable },
  { name: 'user', pathName: ROUTE_USER, Component: UserTable },
  { name: 'users', pathName: ROUTE_USERS, Component: CompanyUsersTable },
  { name: 'charts', pathName: ROUTE_CHARTS, Component: Charts },
  { name: 'settings', pathName: ROUTE_SETTINGS, Component: UserSettings }
];

export const ROUTES_ADMIN_LOGGED_IN = [
  { name: 'archive', pathName: ROUTE_ARCHIVE, Component: CompanyUsersTable },
  ...ROUTES_LOGGED_IN
];

export const ROUTES_LOGGED_OUT = [
  { name: 'login', pathName: ROUTE_LOGIN, Component: Login }
];

export const ROUTES = [...ROUTES_ADMIN_LOGGED_IN, ...ROUTES_LOGGED_OUT];

export const MENU_ITEM_ROUTES = [
  'archive',
  'add skill',
  'users',
  'company skills',
  'charts',
  'settings'
];
