import React, { Fragment } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import ErorrPage from '../common/ErrorPage';
import GoogleLogin from './GoogleLogin';
import Loader from '../common/Loader';

const LOGIN_USER = gql`
  mutation login($webToken: String!) {
    login(webToken: $webToken) {
      token
      defaultView
    }
  }
`;

const Login = ({ refetch, user }) => {
  return (
    <Mutation
      mutation={LOGIN_USER}
      onCompleted={({ login }) => {
        const { token } = login;
        localStorage.setItem('userToken', token);
      }}
    >
      {(login, { loading, error }) => {
        const { message } = error || '';
        if (loading) return <Loader />;
        if (error) return <ErorrPage text={message} />;
        return (
          <Fragment>
            {user ? (
              <ErorrPage text="You are already logged in" />
            ) : (
              <GoogleLogin login={login} refetch={refetch} />
            )}
          </Fragment>
        );
      }}
    </Mutation>
  );
};

export default Login;
