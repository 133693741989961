import React, { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';

const Icons = ({ stars, hearts }) => {
  const items = [];
  if (stars) {
    for (let i = 0; i < stars; i++) {
      items.push(
        <Icon key={i} name="star" className="legend-icon" size="large" />
      );
    }
  }
  if (hearts) {
    for (let i = 0; i < hearts; i++) {
      items.push(
        <Icon
          key={i}
          name="heart"
          className="legend-icon legend-icon-hearts"
          size="large"
        />
      );
    }
  }
  return <Fragment>{items}</Fragment>;
};

export default Icons;
