import React, { useState, useRef } from 'react';
import {
  Accordion,
  Icon,
  Segment,
  Container,
  Header,
  Sticky,
  Ref
} from 'semantic-ui-react';
import classNames from 'classnames';

import Icons from '../SkillEntryForm/Icons';
import {
  ratingLabels,
  likingLabels,
  ratingDescriptions,
  likingDescriptions
} from '../SkillEntryForm/getLabel';
import styles from './legend.module.scss';

function LegendItem({
  active,
  onClick,
  value,
  stars,
  hearts,
  title,
  description
}) {
  return (
    <Accordion>
      <Accordion.Title active={active} index={0} onClick={() => onClick(value)}>
        <div className={styles.container}>
          <Icon name="dropdown" />
          <span className={styles.title}>{title}</span>
          <span className={classNames(styles.title, styles.rating)}>
            <Icons stars={stars} hearts={hearts} />
          </span>
        </div>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <p>{description}</p>
      </Accordion.Content>
    </Accordion>
  );
}

export default function Legend() {
  const [activeRating, setActiveRating] = useState(undefined);
  const [activeLiking, setActiveLiking] = useState(undefined);
  const contextRef = useRef();

  const toggle = (fn, val, prev) => {
    const newVal = prev !== val ? val : undefined;
    fn(newVal);
  };

  return (
    <Ref innerRef={contextRef}>
      <div className="legend-container">
        <Sticky context={contextRef}>
          <Container className="legend">
            <Header as="h2">Legend</Header>
            <Segment>
              {ratingDescriptions.map((desc, index) => (
                <LegendItem
                  key={index}
                  title={ratingLabels[index]}
                  value={index}
                  stars={index + 1}
                  description={desc}
                  onClick={val => toggle(setActiveRating, val, activeRating)}
                  active={activeRating === index}
                />
              ))}
            </Segment>
            <Segment>
              {likingDescriptions.map((desc, index) => (
                <LegendItem
                  key={index}
                  title={likingLabels[index]}
                  value={index}
                  hearts={index + 1}
                  description={desc}
                  onClick={val => toggle(setActiveLiking, val, activeLiking)}
                  active={activeLiking === index}
                />
              ))}
            </Segment>
          </Container>
        </Sticky>
      </div>
    </Ref>
  );
}
